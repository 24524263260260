import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  PROD_URL = "http://danielleswisahealth.com";
  DEV_URL = "http://localhost:8080";
  activeUrl;

  constructor(
    private httpClient: HttpClient
  ) {
    this.activeUrl = this.PROD_URL;
    // this.report();
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    
  }

  report = () => {
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const requestOptions = {
    //   headers: new HttpHeaders({
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Headers": "authorization, content-type, X-Requested-With Accept",
    //     "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, PATCH, OPTIONS"
    //   })
    // };
    // const params = new HttpParams().set("timezone", timezone);
    // this.httpClient.post(`${this.activeUrl}/page-visit`, params, requestOptions).toPromise();
  }

}