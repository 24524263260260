import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from "./home.component";
import { HomeRoutingModule } from './home.routes';
import { DropdownModule } from '../components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  providers: [],
  imports: [
    CommonModule,
    HomeRoutingModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [HomeComponent],
  exports: [HomeComponent]
})
export class HomeModule {}