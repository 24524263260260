import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { trigger, style, transition, animate } from "@angular/animations";

export interface IDropdownItem {
  id: string;
  label: string;
  selected?: boolean;
  icon?: string;
  onClick?: Function;
  show?;
}

export interface IDropdownDefinition {
  placeholder?: string;
  items?: IDropdownItem[];
  getItems?: Function;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [
    trigger(
      "expandAnimation",
      [
        transition(
          ":enter",
          [
            style({ transform: "scaleY(0)", "transform-origin": "top", opacity: 0 }),
            animate("0.1s ease-out", style({ transform: "scaleY(1)", "transform-origin": "top", opacity: 1 }))
          ]
        ),
        transition(
          ":leave",
          [
            style({ transform: "scaleY(1)", "transform-origin": "top", opacity: 1 }),
            animate("0.1s ease-in", style({ transform: "scaleY(0)", "transform-origin": "top", opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class DropdownComponent implements OnInit, OnDestroy {

  public showPopup: boolean = false;
  @ViewChild('togglePopup') togglePopup: ElementRef;
  @ViewChild('popup') popup: ElementRef;
  @Input() definition: IDropdownDefinition;
  @Input() defaultSelection?;
  @Input() data?;
  @Input() emitOnInit?: boolean;
  @Input() enableChangeDetection: boolean;
  @Output() onItemSelected = new EventEmitter<any>();
  selectedItem: IDropdownItem;

  constructor(
  ) {
  }

  ngOnInit() {
    if (typeof this.definition.getItems === "function" && !this.definition.items)
      this.definition.items = this.definition.getItems(this.data);
    for (let item of this.definition.items) {
      if (this.defaultSelection) {
        this.definition.items.map(item => delete item.selected);
        if (this.defaultSelection === item.id) this.selectedItem = item;
      }
      else if (item.selected && this.showOption(item)) {
        item.selected = true;
        this.selectedItem = item;
      }
    }
    // if (!this.selectedItem && !this.definition.placeholder && this.definition.placeholder != "")
    //   this.selectItem(this.definition.items[0]);
    if (this.emitOnInit) this.onItemSelected.emit(this.selectedItem);
  }

  ngOnDestroy() {
  }

  ngOnChanges(change) {
    if (this.enableChangeDetection && change.defaultSelection != null) {
      this.defaultSelection = change.defaultSelection.currentValue;
      if (!(this.defaultSelection != null)) this.unselectAllItems();
      else {
        for (let item of this.definition.items) {
          if (item.id !== this.defaultSelection) continue;
          this.setSelectedItem(item);
        }
      }
    }
  }

  unselectAllItems = () => {
    for (let item of this.definition.items) {
      delete item.selected;
    }
    this.selectedItem = null;
  }

  showOption = (option: IDropdownItem): boolean => {
    if (!(option.show != null) || option.show === true) return true;
    if (typeof option.show === "function")
      return option.show(this.data);
    return false;
  }

  isItemSelected = item => {
    if (this.defaultSelection) {
      if (this.defaultSelection === item.id) return true;
      return false;
    }
    return item.selected;
  }

  toggle = () => this.showPopup = !this.showPopup;
  getPlaceholder = () => this.definition.placeholder || "Not Selected";

  setSelectedItem = (itemToSelect: IDropdownItem) => {
    for (let item of this.definition.items) {
      if (this.isItemSelected(item)) {
        delete item.selected;
        break;
      }
    }
    if (!this.defaultSelection) itemToSelect.selected = true;
    else this.defaultSelection = itemToSelect.id;
    this.selectedItem = itemToSelect;
  }

  selectItem = (itemToSelect: IDropdownItem) => {
    this.setSelectedItem(itemToSelect);
    if (typeof this.selectedItem.onClick === "function")
      this.selectedItem.onClick(itemToSelect.id);
    this.onItemSelected.emit(itemToSelect);
    this.showPopup = false;
  }

  getTitle = (item) => item.title || item.label;

}
