import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fadeAnimation } from '../animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [fadeAnimation]
  // host: { '[@inOutAnimation]': '' }
})
export class HomeComponent implements OnInit, OnDestroy {

  PROD_URL = "http://danielleswisahealth.com";
  DEV_URL = "http://localhost:8080";
  activeUrl;
  public ageDefinition;
  public form: FormGroup;
  selectedAge;
  showLoader: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient
  ) {
    this.activeUrl = this.PROD_URL;
  }

  ngOnInit() {
    this.ageDefinition = {
      items: this.getAges()
    }

    this.form = this.formBuilder.group({
      name: [''],
      phone: [''],
      zip: [''],
      people: [''],
      comments: ['']
    });
    this.form.get('name').setValidators([Validators.minLength(2), Validators.required]);
    this.form.get('phone').setValidators([Validators.required]);
    this.form.get('zip').setValidators([]);
    this.form.get('people').setValidators([]);
  }

  ngOnDestroy() {
  }

  getAges = () => {
    let arr = [];
    for (let i = 1; i < 100; i++) {
      arr.push({ id: i, label: i, selelcted: false });
    }
    return arr;
  }

  onAgeSelected = ($event) => {
    this.selectedAge = $event;
  }

  submit = async (form) => {
    const values = form.value;
    const phone = values.phone;
    const name = values.name;
    let errorMessage;
    if (!phone || phone == "" || typeof phone !== "string" || phone.length !== 10) errorMessage = `Invalid phone number typed`;
    if (!name || name == "" || typeof name !== "string" || name.length === 0) {
      if (!errorMessage) errorMessage = `Invalid name typed`;
      else errorMessage = `Invalid phone and name typed`;
    }
    if (errorMessage) {
      alert(errorMessage);
      return;
    }
    this.showLoader = true;
    const requestOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "authorization, content-type, X-Requested-With Accept",
        "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, PATCH, OPTIONS"
      })
    };
    const params = new HttpParams()
      .set("name", values.name)
      .set("phone", values.phone)
      .set("zip", values.zip)
      .set("age", this.selectedAge)
      .set("people", values.people)
      .set("comments", values.comments);
    const res = await this.httpClient.post(`${this.activeUrl}/send-email`, params, requestOptions).toPromise();
    if (res) alert(`Thank you ${form.value.name}, your information was submitted, I will contact you soon`);
    else alert(`An error has occured, please try again`);
    this.form.reset();
    this.showLoader = false;
  }

}
