import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit, OnDestroy {

  PROD_URL = "http://danielleswisahealth.com";
  DEV_URL = "http://localhost:8080";
  activeUrl;

  constructor(
  ) {
    this.activeUrl = this.PROD_URL;
  }

  ngOnInit() {}

  ngOnDestroy() {
  }

}
