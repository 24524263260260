import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from "./dropdown.component";
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  providers: [],
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  declarations: [DropdownComponent],
  exports: [DropdownComponent],
})

export class DropdownModule {
}