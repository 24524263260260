import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  // { path: "analytics", component: AnalyticsComponent },
  { path: "home", component: HomeComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
